import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { wssLogPrefix } from '../decorators';
import { BunSpotWebSocketManager } from '../SpotWebSocketManager';
import { wsFrontendErrors } from '../websocket-model';
import {
  type WSChannelNames,
  type WSErrorMessage,
  WSGeneralErrors,
  WSIncomingEventTypes,
  WSIncomingEventTypesSpecial,
  type WSIncomingMessagePayload,
  WSOutgoingEventTypesSpecial,
  type WSOutgoingSubscribeMessagePayload,
  type WSOutgoingUnsubscribeMessagePayload,
} from '../websocket-spot-model';

export interface ChannelListenerTemplate<T> {
  onMessage: (event: T) => void;
}

export type OnReconnectCallback = () => void;

export interface SubscribeCallbacks<T = void> {
  success: (data: T) => void;
  fail: (error: Error) => void;
}

export abstract class ChannelTemplate<MessageType extends WSIncomingMessagePayload, ListenerType extends ChannelListenerTemplate<MessageType>> {
  public webSocketManager = BunSpotWebSocketManager;

  protected unsubscribeFromWebsocket?: () => void;

  protected listeners: Array<ListenerType> = [];

  private onReconnectCallbacks: Set<OnReconnectCallback> = new Set();

  public onReconnectAdd = (cb: OnReconnectCallback) => {
    this.onReconnectCallbacks.add(cb);
  };

  public onReconnectDelete = (cb: OnReconnectCallback) => {
    this.onReconnectCallbacks.delete(cb);
  };

  subscribe(listener: ListenerType, callbacks?: SubscribeCallbacks) {
    this.listeners.push(listener);

    if (this.listeners.length === 1) {
      this.openChannel(listener)
        .then(() => callbacks?.success())
        .catch((error) => callbacks?.fail(error));
    } else {
      callbacks?.success();
    }

    return async () => {
      this.listeners = this.listeners.filter((l) => l !== listener);

      if (this.listeners.length === 0) {
        await this.closeChannel();
      }
    };
  }

  protected abstract getSubscribeMessage(listener: ListenerType): WSOutgoingSubscribeMessagePayload;

  protected getUnsubscribeMessage(): WSOutgoingUnsubscribeMessagePayload {
    return {
      type: WSOutgoingEventTypesSpecial.UNSUBSCRIBE,
      channels: [this.channelName],
    };
  }

  protected abstract get channelName(): WSChannelNames;

  protected handleRequestFailure = async (e, cb?: () => Promise<void>) => {
    logger.info(`${wssLogPrefix} ${this.channelName} request failure;`, ' error: ', e, ' error.type: ', e?.type, ' error.error: ', e?.error);

    if ((e as WSIncomingMessagePayload)?.type === wsFrontendErrors.ERROR_WEBSOCKET_CLOSED) {
      // dont retry these as WSS will reopen and will call onConnection again to reconnect.
      return;
    }

    if ((e as WSErrorMessage)?.error === WSGeneralErrors.SUBSCRIPTION_ALREADY_EXISTS) {
      // i guess we are fine already.
      return;
    }

    if (e.message === wsFrontendErrors.ERROR_MAX_ATTEMPTS_REACHED) {
      return;
    }

    if (e.message === wsFrontendErrors.MISSING_API_TOKEN) {
      window.location.reload();
    }

    logger.warn(`${wssLogPrefix} ${this.channelName} retrying request...`);
    await retryService.waitForNextRetryTick();

    await cb?.();
  };

  protected async openChannel(listener: ListenerType): Promise<void> {
    try {
      const tempUnsubscribe = this.unsubscribeFromWebsocket;

      this.unsubscribeFromWebsocket = await this.webSocketManager.subscribe({
        onEvent: (e) => this.onMessage(e),
        onConnection: () => {
          if (this.listeners.length > 0) {
            this.onReconnectCallbacks.forEach((value) => {
              value();
            });

            this.openChannel(listener).catch((e) => {
              logger.warn(`${wssLogPrefix}On connection - failed to open channel ${this.channelName}:`, e);

              this.handleRequestFailure(e, () => this.openChannel(listener));
            });
          }
        },
      });

      tempUnsubscribe?.();
      await this.webSocketManager.request({
        message: this.getSubscribeMessage(listener),
        successMatcher: (m) => m.type === WSIncomingEventTypesSpecial.SUBSCRIPTIONS && !!m.channels.find((c) => c.name === this.channelName),
        failureMatcher: (m) => m.type === WSIncomingEventTypes.ERROR && m.channel_name === this.channelName,
      });
    } catch (e) {
      logger.warn(`${wssLogPrefix}Failed to Open Channel ${this.channelName}:`, e);

      this.handleRequestFailure(e, () => this.openChannel(listener));
    }
  }

  protected async closeChannel() {
    logger.warn('closing channel', this.channelName);

    this.unsubscribeFromWebsocket?.();
    this.unsubscribeFromWebsocket = undefined;

    if (this.webSocketManager.getReadyState() === undefined) {
      return;
    }

    try {
      await this.webSocketManager.request({
        message: this.getUnsubscribeMessage(),
        successMatcher: (m) => m.type === WSIncomingEventTypesSpecial.UNSUBSCRIBED && m.channel_name === this.channelName,
      });
    } catch (e) {
      logger.warn(`${wssLogPrefix}Failed to close channel:`, e);

      this.handleRequestFailure(e, () => this.closeChannel());
    }
  }

  /**
   * Overwrite this if you need more specific events
   * @param event any given websocket event of any currently subscribed channel
   * @param listener any given listener currently subscribed to this channel
   * @returns true if this channel is interested in this event
   */
  protected messageFilter({ event }: { event: MessageType; listener: ListenerType }) {
    if ('channel_name' in event) {
      return event.channel_name === this.channelName;
    }

    return false;
  }

  protected onMessage(event) {
    const message = event;

    this.listeners.forEach((listener) => {
      if (this.messageFilter({ event, listener })) {
        listener.onMessage(message);
      }
    });
  }
}
